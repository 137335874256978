.mlr10 {
  margin: 0 10px;
}

.flexDisplay {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.headerTop {
  font-size: 24px;
  color: #2f394e;
  font-weight: 500;
  margin: 0 0 20px 0;
}
.subHeaderTop {
  font-size: 20px;
  color: rgba(59, 63, 71, 0.6);
  margin: 0;
}

button.customMade {
  font-size: 14px;
  height: 40px;
  padding: 9px 12px;
}
.datePickerDisplay {
  display: inline-block;
  margin: 0 10px;
}

.pagecontent.tableActivityURL .table-responsive {
  height: calc(100vh - 230px);
  overflow: auto;
}

.dashboardTable .table-responsive {
  max-height: 185px;
  overflow: auto;
}
.dashboardTable .table {
  margin-bottom: 0 !important;
}

.dashboardTable .card-body {
  padding: 10px 15px 0 15px !important;
}
.dashboardTable .card-footer {
  padding: 0 15px 15px !important;
}
.projectLetter {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  /*width: 32px;*/
  /*height: 32px;*/
  line-height: 32px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
}
.displayContents {
  display: contents;
}
.percentTagImageTop {
  float: right;
  top: -6px;
  right: 9px;
  z-index: 2;
  padding: 2px 8px;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
}
.shotBody {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.17%;
}

.dashboardTable.tableBigHeight .table-responsive {
  max-height: 350px !important;
}
@media only screen and (max-width: 767px) {
  .btn + .btn,
  .btn-group + .btn-group {
    margin-left: 0 !important;
  }
  .datePickerDisplay {
    display: inherit;
    margin: auto;
  }
}
.react-datepicker__input-container {
  cursor: pointer;
  min-width: 120px;
}

.video-player {
  text-align: center;
  width: auto;
  height: 100% !important;
  display: block;
  background: #000;
}
.react-video-player {
  /* display: inline-block; */
  margin: 0 auto;
  border: 2px solid grey;
  border-radius: 10px !important;
  overflow: hidden;
  height: 100% !important;
  width: 100% !important;
}

.video-modal .close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  font-size: 34px;
}

.video-wrapper {
  height: 70vh;
  width: 100%;
}

.custom-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: background 0.2s;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* Thumb size */
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 6px solid #73cac0 !important;
}

/* For Firefox */
.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  border: 4px solid #9a96f9 !important;
}
